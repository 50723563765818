<template>
  <div>
    <c-tab
      type="pagetop"
      :tabItems.sync="tabItems"
      :dense="true"
      :inlineLabel="true"
      v-model="tab"
      align="left"
    >
      <template v-slot:default="tab">
        <component
          :is="tab.component"
        />
      </template>
    </c-tab>
  </div>
</template>

<script>
export default {
  name: 'company-tab',
  data() {
    return {
      tab: 'company',
      tabItems: [
        // 회사 관리
        { name: 'company', icon: 'list', label: '회사 관리', component: () => import(`${'./companyManage.vue'}`) },
        // 사업장 관리
        { name: 'plant', icon: 'groups', label: '사업장 관리', component: () => import(`${'./plantManage.vue'}`) },
      ],
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
    },
  }
};
</script>
